import { createSlice } from "@reduxjs/toolkit";
import { bindActionCreators } from "redux";

const initialState = {
  firstLoad: true,
  currentPage: "main",
};

export const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    resetCurrentPage: (state) => {
      state.currentPage = "main";
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setFirstLoad: (state) => {
      state.firstLoad = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  resetCurrentPage,
  setCurrentPage,
  setFirstLoad,
} = pageSlice.actions;

export const selectPage = (state) => state.page;

export const mapStateToProps = (state) => ({
  currentPage: state.page.currentPage,
});

export const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ resetCurrentPage, setCurrentPage }, dispatch);
};

export default pageSlice.reducer;
